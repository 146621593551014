import type { PageType } from "~~/models/pages";
import { SiteLocale } from "~~/server/graphql/datocms/generated";
import { getCultureFromDatoLocale } from "./localeMapping";

export function getSlugPrefix(pageType: PageType, locale: SiteLocale | string) {
  const culture = typeof locale === typeof SiteLocale
    ? getCultureFromDatoLocale(locale as SiteLocale)
    : locale;

  switch (pageType) {
    case "category":
      switch (culture) {
        case "en":
          return "/offer";
        case "fr":
          return "/offrir";
        default:
          return "/aanbod";
      }

    case "legal":
      switch (culture) {
        case "en":
          return "/legal";
        case "fr":
          return "/legal";
        default:
          return "/legal";
      }

    case "series":
      switch (culture) {
        case "en":
          return "/series";
        case "fr":
          return "/series";
        default:
          return "/series";
      }

    case "solution":
      switch (culture) {
        case "en":
          return "/solutions";
        case "fr":
          return "/solutions";
        default:
          return "/oplossingen";
      }

    default:
      return "";
  }
}
