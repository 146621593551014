import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/pages/runtime/validate.js";
import referrer_45global from "/vercel/path0/app/middleware/referrer.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  referrer_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "block-extensions": () => import("/vercel/path0/app/middleware/block-extensions.ts")
}