import type { WebhookPayload } from "@esc-bv/ebs-nuxt-datocms-preview/server/api/datocms/preview-links";
import { getDatoLocaleFromCulture } from "~~/server/utils/localeMapping";
import { getSlugPrefix } from "~~/server/utils/slugPrefixes";

export default defineAppConfig({

  ui: {
    primary: "main",
    gray: "neutral",
    breadcrumb: {
      ol: "flex items-center gap-x-xs",
      li: "flex items-center gap-x-xs text-base leading-6 min-w-0",
      base: "flex items-center gap-x-xs group font-medium min-w-0",
    },
    button:
    {
      base: "hover:no-underline",
      font: "font-normal", // figma defines font-medium, but it doesn't look the same
      rounded: "rounded",
      size: {
        sm: "text-lg leading-[21px]",
        lg: "text-lg leading-[21px]",
      },
      gap: {
        lg: "gap-x-xs",
      },
      padding: {
        sm: "px-sm py-xs",
        lg: "px-lg py-sm",
      },
      color: {
        white: {
          ghost: "text-white hover:!bg-transparent !px-0",
          solid: "text-primary hover:bg-white hover:opacity-80",
          outline: `
            ring-1 ring-white text-white ring-inset ring-current disabled:bg-transparent aria-disabled:bg-transparent
            focus-visible:ring-2 focus-visible:ring-white hover:bg-white hover:text-primary hover:ring-0
          `,
        },
        black: {
          solid: "hover:!bg-black hover:opacity-80",
          outline: `
            ring-1 ring-inset ring-current disabled:bg-transparent aria-disabled:bg-transparent
            focus-visible:ring-2 focus-visible:ring-black hover:bg-black hover:text-white hover:ring-0
          `,
        },
      },
      variant:
      {
        solid: "hover:!bg-{color}-500 hover:opacity-80",
        ghost: "!px-0",
        outline: "hover:!bg-{color} hover:!bg-{color}-500 hover:text-white hover:ring-0",
      },
      icon: {
        size: {
          lg: "size-6",
        },
      },
      default:
      {
        size: "lg",
      },
    },
    container: {
      base: "w-full",
      padding: "px-xl",
      constrained: "max-w-[1184px]",
    },
    formGroup: {
      label: {
        base: "block font-bold text-gray-800 dark:text-gray-200",
        wrapper: "flex content-center items-center justify-start gap-2xs mb-xs",
      },
      hint: "text-gray-400",
      help: "mt-xs text-[0.875rem] text-primary-900",
      error: "mt-xs text-[0.875rem] text-red-500 dark:text-red-400",
      default: {
        size: "xl",
      },
    },
    input: {
      color: {
        white: {
          outline: `
            shadow-0
            bg-white dark:bg-gray-900 disabled:bg-gray-200
            text-gray-900 dark:text-white
            ring-1 ring-inset ring-gray-400 dark:ring-gray-700 disabled:ring-gray-200
            focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-400
            hover:ring-gray-600
          `,
        },
      },
      padding: {
        xl: "px-[1.25rem] py-[0.875rem]",
      },
      rounded: "rounded-sm",
      placeholder: "placeholder-gray-600",
      default: {
        size: "xl",
      },
    },
    modal: {
      rounded: "rounded",
    },
    pagination: {
      wrapper: "gap-[6px] flex-wrap",
      base: "font-bold",
      rounded: "rounded",
    },
    popover: {
      rounded: "rounded",
    },
    tabs:
    {
      list:
      {
        background: "bg-gray-200",
      },
    },
    select: {
      color: {
        white: {
          outline: `
            shadow-0
            bg-white dark:bg-gray-900 disabled:bg-gray-200
            text-gray-900 dark:text-white
            ring-1 ring-inset ring-gray-400 dark:ring-gray-700 disabled:ring-gray-200
            focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-400
            hover:ring-gray-600
          `,
        },
      },
      icon: {
        base: "flex-shrink-0 text-primary-500 dark:text-gray-500",
      },
      padding: {
        xl: "px-[1.25rem] py-[0.875rem]",
      },
      rounded: "rounded-sm",
      placeholder: "placeholder-gray-600",
      default: {
        size: "xl",
      },
    },
    selectMenu: {
      option: {
        color: "text-gray-900 dark:text-white hover:bg-gray-700 hover:bg-opacity-10",
        padding: "mx-sm my-xs p-[0.375rem]",
        rounded: "rounded-none",
        size: "text-base",
      },
      padding: "p-0",
      rounded: "rounded-sm",
      shadow: "shadow-sm",
    },
    textarea: {
      color: {
        white: {
          outline: `
            shadow-0
            bg-white dark:bg-gray-900 disabled:bg-gray-200
            text-gray-900 dark:text-white
            ring-1 ring-inset ring-gray-400 dark:ring-gray-700 disabled:ring-gray-200
            focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-400
            hover:ring-gray-600
          `,
        },
      },
      padding: {
        xl: "px-[1.25rem] py-md",
      },
      rounded: "rounded-sm",
      placeholder: "placeholder-gray-600",
      default: {
        size: "xl",
      },
    },
  },

  datoCmsPlugin: {
    secretValue: "c463bed0339463c94a2e8e4c98e2f69f",
  },

  datoCmsPreview: {
    webhookSecretValue: "2367b6f27feb456697b932bd0e2d4e59",
    cryptoPassword: "e9e72f240f964664bc465065dc4055e7",
    generatePreviewUrl: generatePreviewUrl,
  },
});

function generatePreviewUrl({ item, itemType, locale }: WebhookPayload) {
  const localePrefix = locale.toLowerCase();
  const siteLocale = getDatoLocaleFromCulture(localePrefix);
  const slug = (item.attributes.slug && item.attributes.slug[locale]) || "";
  switch (itemType.attributes.api_key) {
    case "category_page":
      return `/${localePrefix}${getSlugPrefix("category", siteLocale)}/${slug}`;
    case "error404":
      return `/${localePrefix}/datocms-preview-404-page`;
    case "layout":
      return `/${localePrefix}/`;
    case "legal_page":
      return `/${localePrefix}/legal/${slug}`;
    case "page":
      return `/${localePrefix}/${slug}`;
    case "series_page":
      return `/${localePrefix}${getSlugPrefix("series", siteLocale)}/${slug}`;
    case "solution_page":
      return `/${localePrefix}${getSlugPrefix("solution", siteLocale)}/${slug}`;
    default:
      return null;
  }
}
