import revive_payload_client_OzGmW0Iajy from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YbUxm02bKT from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oBtuhkLDoQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Ns3dfdrRnB from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.10.7_jit_9774926edc7c06b90b096ed4ce7f4b08/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_xzuDiuglRn from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_E8BsZQxDfs from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yeNOUWj1qR from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5fWat9SK9Z from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_gSIwybnkaV from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_e83YeCUjwM from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescr_efb4b9054cccfe3302e1eb65515fbe9a/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_kFufxcOvwp from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescr_efb4b9054cccfe3302e1eb65515fbe9a/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_YLkbvfzMbR from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescr_efb4b9054cccfe3302e1eb65515fbe9a/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_CS8Nw8Hh9h from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_8puF34GiTR from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.10.7_jiti@2.4_e622201c6a45acd8e01174db2b51a27e/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import siteConfig_obY9wsaQLg from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.10.7_jiti@_b800ac1c41a55abdbc8245edb4b5591b/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_j8nCpVDg3R from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.10.7_jiti@_b800ac1c41a55abdbc8245edb4b5591b/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_2T4ClhxBQQ from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.10.7_jiti@_b800ac1c41a55abdbc8245edb4b5591b/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_F8yZBaNNkg from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.10.7_jiti@_b800ac1c41a55abdbc8245edb4b5591b/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import switch_locale_path_ssr_nDes55ebLb from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_ro_0085807422c63da3106c6feffff08e65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_XGojWqxh6f from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_ro_0085807422c63da3106c6feffff08e65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_PWATrNg7kM from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_ro_0085807422c63da3106c6feffff08e65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import dompurify_TR6zsolR57 from "/vercel/path0/app/plugins/dompurify.ts";
import sentry_client_a4hAB0MOGi from "/vercel/path0/app/plugins/sentry.client.ts";
import ssg_detect_jpT2PzeFu0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_ro_0085807422c63da3106c6feffff08e65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_OzGmW0Iajy,
  unhead_YbUxm02bKT,
  router_oBtuhkLDoQ,
  _0_siteConfig_Ns3dfdrRnB,
  payload_client_xzuDiuglRn,
  navigation_repaint_client_E8BsZQxDfs,
  check_outdated_build_client_yeNOUWj1qR,
  chunk_reload_client_5fWat9SK9Z,
  components_plugin_zlvi6dcIsi,
  prefetch_client_gSIwybnkaV,
  slideovers_e83YeCUjwM,
  modals_kFufxcOvwp,
  colors_YLkbvfzMbR,
  plugin_client_CS8Nw8Hh9h,
  plugin_8puF34GiTR,
  siteConfig_obY9wsaQLg,
  inferSeoMetaPlugin_j8nCpVDg3R,
  titles_2T4ClhxBQQ,
  defaultsWaitI18n_F8yZBaNNkg,
  switch_locale_path_ssr_nDes55ebLb,
  route_locale_detect_XGojWqxh6f,
  i18n_PWATrNg7kM,
  dompurify_TR6zsolR57,
  sentry_client_a4hAB0MOGi,
  ssg_detect_jpT2PzeFu0
]