
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_91slug_93_9365A6ldxcc4Meta } from "/vercel/path0/app/pages/[[slug]].vue?macro=true";
import { default as compareGLNu43FLslMeta } from "/vercel/path0/app/pages/catalog/compare.vue?macro=true";
import { default as indexStnIlg78KOMeta } from "/vercel/path0/app/pages/catalog/index.vue?macro=true";
import { default as _91slug_93WJjvBKXw7XMeta } from "/vercel/path0/app/pages/categories/[slug].vue?macro=true";
import { default as contact1mYQMGppy4Meta } from "/vercel/path0/app/pages/contact.vue?macro=true";
import { default as developers8DStnDjZkWMeta } from "/vercel/path0/app/pages/dev/developers.vue?macro=true";
import { default as indexrFXHZARUB2Meta } from "/vercel/path0/app/pages/dev/index.vue?macro=true";
import { default as _91slug_93gb4eo8TLAGMeta } from "/vercel/path0/app/pages/legal/[slug].vue?macro=true";
import { default as _91slug_93_45_45_91id_93ESr6ItnclnMeta } from "/vercel/path0/app/pages/product/[slug]--[id].vue?macro=true";
import { default as _91slug_93cUQO8rkDl4Meta } from "/vercel/path0/app/pages/series/[slug].vue?macro=true";
import { default as _91slug_93v4bgK4kG1cMeta } from "/vercel/path0/app/pages/solutions/[slug].vue?macro=true";
import { default as component_45stubLIeHHnrra5Meta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubLIeHHnrra5 } from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+kv@1.0.1_bufferutil@4.0.9_f2ee0b2aa361a563cde45c11279eca9b/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl",
    path: "/nl/:slug?",
    meta: _91_91slug_93_9365A6ldxcc4Meta || {},
    component: () => import("/vercel/path0/app/pages/[[slug]].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug?",
    meta: _91_91slug_93_9365A6ldxcc4Meta || {},
    component: () => import("/vercel/path0/app/pages/[[slug]].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug?",
    meta: _91_91slug_93_9365A6ldxcc4Meta || {},
    component: () => import("/vercel/path0/app/pages/[[slug]].vue")
  },
  {
    name: "catalog-compare___nl",
    path: "/nl/catalog/compare",
    component: () => import("/vercel/path0/app/pages/catalog/compare.vue")
  },
  {
    name: "catalog-compare___fr",
    path: "/fr/catalog/compare",
    component: () => import("/vercel/path0/app/pages/catalog/compare.vue")
  },
  {
    name: "catalog-compare___en",
    path: "/en/catalog/compare",
    component: () => import("/vercel/path0/app/pages/catalog/compare.vue")
  },
  {
    name: "catalog___nl",
    path: "/nl/catalog",
    component: () => import("/vercel/path0/app/pages/catalog/index.vue")
  },
  {
    name: "catalog___fr",
    path: "/fr/catalog",
    component: () => import("/vercel/path0/app/pages/catalog/index.vue")
  },
  {
    name: "catalog___en",
    path: "/en/catalog",
    component: () => import("/vercel/path0/app/pages/catalog/index.vue")
  },
  {
    name: "categories-slug___nl",
    path: "/nl/aanbod/:slug()",
    meta: _91slug_93WJjvBKXw7XMeta || {},
    component: () => import("/vercel/path0/app/pages/categories/[slug].vue")
  },
  {
    name: "categories-slug___fr",
    path: "/fr/offrir/:slug()",
    meta: _91slug_93WJjvBKXw7XMeta || {},
    component: () => import("/vercel/path0/app/pages/categories/[slug].vue")
  },
  {
    name: "categories-slug___en",
    path: "/en/offer/:slug()",
    meta: _91slug_93WJjvBKXw7XMeta || {},
    component: () => import("/vercel/path0/app/pages/categories/[slug].vue")
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    meta: contact1mYQMGppy4Meta || {},
    component: () => import("/vercel/path0/app/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    meta: contact1mYQMGppy4Meta || {},
    component: () => import("/vercel/path0/app/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    meta: contact1mYQMGppy4Meta || {},
    component: () => import("/vercel/path0/app/pages/contact.vue")
  },
  {
    name: "dev-developers___nl",
    path: "/nl/dev/developers",
    component: () => import("/vercel/path0/app/pages/dev/developers.vue")
  },
  {
    name: "dev-developers___fr",
    path: "/fr/dev/developers",
    component: () => import("/vercel/path0/app/pages/dev/developers.vue")
  },
  {
    name: "dev-developers___en",
    path: "/en/dev/developers",
    component: () => import("/vercel/path0/app/pages/dev/developers.vue")
  },
  {
    name: "dev___nl",
    path: "/nl/dev",
    component: () => import("/vercel/path0/app/pages/dev/index.vue")
  },
  {
    name: "dev___fr",
    path: "/fr/dev",
    component: () => import("/vercel/path0/app/pages/dev/index.vue")
  },
  {
    name: "dev___en",
    path: "/en/dev",
    component: () => import("/vercel/path0/app/pages/dev/index.vue")
  },
  {
    name: "legal-slug___nl",
    path: "/nl/legal/:slug()",
    meta: _91slug_93gb4eo8TLAGMeta || {},
    component: () => import("/vercel/path0/app/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___fr",
    path: "/fr/legal/:slug()",
    meta: _91slug_93gb4eo8TLAGMeta || {},
    component: () => import("/vercel/path0/app/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___en",
    path: "/en/legal/:slug()",
    meta: _91slug_93gb4eo8TLAGMeta || {},
    component: () => import("/vercel/path0/app/pages/legal/[slug].vue")
  },
  {
    name: "product-slug--id___nl",
    path: "/nl/product/:slug()--:id()",
    component: () => import("/vercel/path0/app/pages/product/[slug]--[id].vue")
  },
  {
    name: "product-slug--id___fr",
    path: "/fr/product/:slug()--:id()",
    component: () => import("/vercel/path0/app/pages/product/[slug]--[id].vue")
  },
  {
    name: "product-slug--id___en",
    path: "/en/product/:slug()--:id()",
    component: () => import("/vercel/path0/app/pages/product/[slug]--[id].vue")
  },
  {
    name: "series-slug___nl",
    path: "/nl/series/:slug()",
    meta: _91slug_93cUQO8rkDl4Meta || {},
    component: () => import("/vercel/path0/app/pages/series/[slug].vue")
  },
  {
    name: "series-slug___fr",
    path: "/fr/series/:slug()",
    meta: _91slug_93cUQO8rkDl4Meta || {},
    component: () => import("/vercel/path0/app/pages/series/[slug].vue")
  },
  {
    name: "series-slug___en",
    path: "/en/series/:slug()",
    meta: _91slug_93cUQO8rkDl4Meta || {},
    component: () => import("/vercel/path0/app/pages/series/[slug].vue")
  },
  {
    name: "solutions-slug___nl",
    path: "/nl/oplossingen/:slug()",
    meta: _91slug_93v4bgK4kG1cMeta || {},
    component: () => import("/vercel/path0/app/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___fr",
    path: "/fr/solutions/:slug()",
    meta: _91slug_93v4bgK4kG1cMeta || {},
    component: () => import("/vercel/path0/app/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___en",
    path: "/en/solutions/:slug()",
    meta: _91slug_93v4bgK4kG1cMeta || {},
    component: () => import("/vercel/path0/app/pages/solutions/[slug].vue")
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/nl/categorieen/:pathMatch(.*)",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/fr/categorieen/:pathMatch(.*)",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/en/categorieen/:pathMatch(.*)",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/nl/onderdelen",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/fr/onderdelen",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/en/onderdelen",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/nl/over-joris-van-dyck",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/fr/over-joris-van-dyck",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/en/over-joris-van-dyck",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/nl/service",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/fr/service",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/en/service",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/nl/verdeelpunt",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/fr/verdeelpunt",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/en/verdeelpunt",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/nl/verhuur-paginas/:pathMatch(.*)",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/fr/verhuur-paginas/:pathMatch(.*)",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/en/verhuur-paginas/:pathMatch(.*)",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/nl/sitemap.xml",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/fr/sitemap.xml",
    component: component_45stubLIeHHnrra5
  },
  {
    name: component_45stubLIeHHnrra5Meta?.name,
    path: "/en/sitemap.xml",
    component: component_45stubLIeHHnrra5
  }
]