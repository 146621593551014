import type { FilterGroupMultiSelect } from "~~/models/filters";
import type { Pagination } from "~~/models/pagination";
import type { ProductDto } from "~~/models/products";
import type { CatalogSearchQuery } from "~~/models/search";
import type { ProductSearchPropertyValueDto } from "~~/server/api/ecom/search/index.post";

export function useCatalogPagination(): Ref<Pagination> {
  return useState<Pagination>(
    "catalog-pagination",
    () => reactive<Pagination>({
      pageSize: 0,
      totalItems: 0,
    }),
  );
}

export function useCatalogProductsDisplay(): Ref<ProductDto[]> {
  return useState<ProductDto[]>(
    "catalog-products-display",
    () => reactive<Array<ProductDto>>([]),
  );
}

export function useCatalogProductsPaged(): Ref<any> {
  return useState<any>(
    "catalog-products-paged",
    () => reactive<any>([]),
  );
}

export function useCatalogPropertyFilters(): Ref<Array<FilterGroupMultiSelect>> {
  return useState<Array<FilterGroupMultiSelect>>(
    "catalog-property-filters",
    () => reactive<Array<FilterGroupMultiSelect>>([]),
  );
}

export function useCatalogPropertyFiltersHidden(): Ref<Array<number>> {
  return useState<Array<number>>(
    "catalog-property-filters-hidden",
    () => reactive<Array<number>>([]),
  );
}

export function useCatalogSearchQuery(): Ref<CatalogSearchQuery> {
  return useState<CatalogSearchQuery>(
    "catalog-search-query",
    () => reactive<CatalogSearchQuery>({
      page: 1,
      propertyFilters: [] as Array<ProductSearchPropertyValueDto>,
      term: undefined,
    }),
  );
}

export function useProductComparisonList() {
  return useState<Set<ProductDto>>(
    "product-comparison-list",
    () => ref(new Set([])),
  );
}

export function useReferrer(): Ref<string | undefined> {
  return useState<string | undefined>("referrer", () => ref(undefined));
}

export function useSearchTerm(): Ref<string> {
  return useState<string>(
    "search-term",
    () => ref(""),
  );
}
